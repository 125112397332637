/*---------------------------------
    Fonts
  ---------------------------------*/
/*---------------------------------
    Font sizes
  ---------------------------------*/
/*---------------------------------
    Font weights
  ---------------------------------*/
/*--------------------------------
    Colors
  ---------------------------------*/
/*---------------------------------
    Fonts
  ---------------------------------*/
/*---------------------------------
    Font sizes
  ---------------------------------*/
/*---------------------------------
    Font weights
  ---------------------------------*/
/*--------------------------------
    Colors
  ---------------------------------*/
body {
  background: #F0F5FF;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #1B1B1B;
  scroll-behavior: smooth;
}

h1 {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 34px;
  color: #018EBE;
  line-height: 41px;
}

h2 {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 30px;
  color: #1B1B1B;
  line-height: 22px;
  text-align: center;
}

h3 {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 24px;
  color: #018EBE;
  line-height: 30px;
}

h4 {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #1B1B1B;
  line-height: 22px;
  letter-spacing: 0.01em;
}

h5 {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 24px;
  color: #1B1B1B;
  line-height: 29px;
}

h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: #018EBE;
}

p {
  line-height: 22px;
}

.navbar {
  border-radius: 0;
  margin-bottom: 0;
}

button:focus, .btn:focus {
  outline: none;
  border: none;
}

.btn-primary {
  color: #018EBE;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  background: transparent;
  box-shadow: -10px -10px 30px #FFFFFF, 10px 10px 30px rgba(1, 142, 190, 0.4);
  border-radius: 16px;
  border: none;
  padding: 19px 24px 17px 26px;
  width: inherit;
}

.btn-primary:focus, .btn-primary:hover, .btn-primary:disabled {
  background: transparent;
  color: #018EBE;
  border: none;
  box-shadow: -15px -15px 30px #FFFFFF, 15px 15px 30px rgba(1, 142, 190, 0.4);
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active {
  background: transparent;
  border: none;
  color: #018EBE;
  outline: none;
}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus {
  background: linear-gradient(317.7deg, rgba(255, 255, 255, 0.4) 105.18%), #F0F5FF;
  background-blend-mode: soft-light, normal;
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  box-shadow: inset -5px -5px 10px #FAFBFF, inset 5px 5px 10px rgba(1, 142, 190, 0.4);
}

.btn:not(:disabled):not(.disabled) {
  border: 1px solid transparent;
}

a.btn-primary {
  display: block;
  text-align: center;
}

.inner-padding {
  padding: 0 25px;
}

/*---------------------------------
    Media Queries
  ---------------------------------*/
@media (min-width: 768px) {
  h3 {
    font-size: 27px;
    line-height: 37px;
  }
}

@media (min-width: 992px) {
  h1 {
    font-size: 64px;
  }
  h2 {
    font-size: 46px;
  }
  h4 {
    font-size: 20px;
    line-height: 24px;
  }
}

/*---------------------------------
    header
  ---------------------------------*/
/*hamburger menu*/
.navbar-toggler {
  padding: 0;
  width: 58px;
  height: 56px;
  z-index: 2;
  border: none;
}

.navbar-toggler-icon {
  position: relative !important;
  transition: background-color 0s linear .13s;
}

.navbar-toggler-icon:before, .navbar-toggler-icon:after {
  position: absolute;
  content: "";
  display: block;
  top: 8px;
  transition: top 0.1s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s, transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.navbar-toggler-icon:after {
  display: block;
  top: -8px;
}

.navbar-toggler-icon, .navbar-toggler-icon:before, .navbar-toggler-icon:after {
  position: absolute;
  width: 30px;
  height: 3px;
  transition-timing-function: ease;
  transition-duration: .15s;
  transition-property: transform;
  border-radius: 4px;
  background-color: #018EBE;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  transition-delay: .22s;
  background-color: transparent !important;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  top: -12px;
  height: 3px;
  transition: top 0.1s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.15s, transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  transform: translate3d(0, 10px, 0) rotate(45deg);
  background-color: #018EBE;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  top: -12px;
  height: 3px;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.22s;
  transform: translate3d(0, 10px, 0) rotate(-45deg);
  background-color: #018EBE;
}

/*hamburger menu end*/
.navbar-nav a.nav-link {
  color: #1B1B1B;
  font-size: 30px;
}

.navbar-nav .phone {
  display: none;
}

.navbar {
  padding: 9px 0px 10px 10px;
}

.navbar-brand {
  background: url(../images/logo/logo.svg) no-repeat 0 0;
  width: 154px;
  height: 46px;
  padding-top: 0;
  margin-top: 10px;
}

.navbar-collapse {
  padding: 25px 5px;
}

.header {
  padding: 68px 20px 80px 10px;
  background: url(../images/bg/mob-bg.png) no-repeat center;
}

.header h1 {
  line-height: 41px;
  padding-bottom: 23px;
}

.header p {
  color: rgba(27, 27, 27, 0.7);
  line-height: 22px;
  font-size: 18px;
}

.header .header-btn {
  display: none;
}

/*---------------------------------
    Content
  ---------------------------------*/
.block-a {
  justify-content: center;
  padding: 19px 0 0 0;
}

.block-a h2 {
  padding-bottom: 32px;
}

.good-section {
  text-align: center;
  padding: 0 25px;
  margin-bottom: 60px;
}

.good-section img {
  margin: 0 0 0 13px;
}

.good-section .flx-holder {
  padding: 0 7px 15px 0;
  display: flex;
}

.good-section .flx-holder h4 {
  text-align: left;
}

.good-section .flx-holder h4 span {
  padding-top: 5px;
  display: block;
}

.good-section .flx-holder .price-block {
  padding: 4px 0 0 0;
}

.good-section .price {
  line-height: 20px;
  display: flex;
  font-size: 22px;
  text-align: left;
  margin-bottom: -1px;
}

.good-section .price strong {
  color: #018EBE;
  font-weight: 700;
  font-size: 22px;
  padding-right: 6px;
}

.good-section .terms {
  font-size: 13px;
  margin-bottom: 5px;
  text-align: left;
}

.good-section .goods-descr {
  text-align: left;
  font-style: italic;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 20px;
}

.good-section .goods-descr span {
  padding-top: 3px;
  display: inline-block;
}

.block-b {
  justify-content: center;
  padding: 28px 25px 28px 25px;
}

.block-b .row {
  width: 100%;
}

.block-b h2 {
  margin-bottom: 59px;
}

.block-b .cont-holder {
  text-align: center;
  margin-bottom: 59px;
}

.block-b svg {
  margin-bottom: 16px;
}

.block-b h3 {
  margin-bottom: 15px;
}

.block-b p {
  line-height: 22px;
}

.block-c {
  justify-content: center;
  padding-bottom: 80px;
}

.block-c h2 {
  margin-bottom: 23px;
}

.block-c p {
  line-height: 22px;
  padding-bottom: 8px;
}

.side-logo {
  display: none;
}

.block-d {
  text-align: center;
  justify-content: center;
  padding-bottom: 80px;
}

.block-d h5 {
  text-align: center;
  margin-bottom: 20px;
}

.block-d h6 {
  margin-bottom: 5px;
}

.block-d .phone {
  display: block;
  font-family: Montserrat;
  font-size: 22px;
  letter-spacing: 0.03em;
  color: #1B1B1B;
  margin-bottom: 17px;
}

.block-d .address {
  margin-bottom: 9px;
}

.mob-hidden {
  display: none;
}

.map-holder {
  padding: 0;
  padding-bottom: 33px;
}

footer {
  text-align: center;
  background: #018EBE;
  padding: 40px 0 80px 0;
}

/*---------------------------------
    Media Queries
  ---------------------------------*/
@media (min-width: 576px) {
  .good-section .flx-holder {
    flex-direction: column;
  }
  .good-section .flx-holder h4 {
    text-align: center;
  }
  .good-section .flx-holder .price-block {
    margin: 0 auto;
  }
  .good-section .goods-descr {
    text-align: center;
  }
}

@media (min-width: 768px) {
  .good-section .btn {
    max-width: 246px;
  }
  .img-holder {
    height: 350px;
    margin-bottom: 32px;
  }
  .img-holder img {
    height: inherit;
  }
  .flx-holder h4 {
    min-height: 120px;
  }
  .goods-descr {
    min-height: 70px;
  }
  .price-block {
    min-height: 100px;
  }
}

@media (min-width: 992px) {
  .container-fluid {
    background: url(../images/bg/desktop-bg.png) no-repeat right 210px;
    background-size: 50%;
  }
  .navbar {
    padding: 63px 66px 10px 105px;
    align-items: flex-start;
  }
  .navbar-collapse {
    padding: 12px 0 25px 0;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 15px;
    padding-left: 15px;
  }
  .navbar-expand-lg .navbar-nav .nav-link:first-child {
    padding-left: 0;
  }
  .navbar-expand-lg .navbar-nav .nav-link.phone {
    display: block;
    color: #018EBE;
    font-size: 26px;
    font-weight: 600;
    padding-top: 4px;
    padding-left: 0;
    margin-left: 10%;
  }
  .navbar-nav {
    width: 100%;
  }
  .navbar-nav a.nav-link {
    font-size: 18px;
  }
  .navbar-expand-lg .navbar-nav {
    justify-content: flex-end;
  }
  .navbar-brand {
    width: 188px;
    height: 144px;
    background: url(../images/logo/logo-desktop.svg) no-repeat 0 0;
  }
  .header {
    background-image: none;
    padding-top: 140px;
    padding-left: 105px;
    margin-bottom: 235px;
  }
  .header h1 {
    line-height: 78px;
    display: block;
    width: 60%;
    padding-bottom: 40px;
  }
  .header p {
    font-size: 24px;
    line-height: 35px;
    display: block;
    width: 50%;
    margin-bottom: 87px;
  }
  .header .header-btn {
    display: block;
    width: 246px;
  }
  .block-a {
    margin-bottom: 100px;
    align-items: stretch;
  }
  .block-a h2 {
    padding-bottom: 70px;
  }
  .price-block {
    min-height: 65px;
  }
  .price-block span {
    display: flex;
    padding-bottom: 7px;
    align-items: center;
  }
  .good-section .price {
    font-size: 16px;
  }
  .good-section .price strong {
    font-size: 24px;
  }
  .block-b h2 {
    margin-bottom: 100px;
  }
  .block-b .row {
    justify-content: space-evenly;
  }
  .edv-holder {
    justify-content: space-evenly;
  }
  .block-c {
    justify-content: space-evenly;
    align-items: center;
  }
  .block-c h2 {
    text-align: left;
    margin-bottom: 30px;
  }
  .block-c p {
    margin-bottom: 60px;
    line-height: 38px;
  }
  .block-c .btn-primary {
    max-width: 246px;
  }
  .side-logo {
    display: block;
  }
  .side-logo img {
    width: inherit;
  }
  .block-d {
    position: relative;
    padding-bottom: 0;
  }
  .block-d .contacts-holder {
    text-align: left;
    padding: 60px 50px 0 78px;
    position: absolute;
    background-color: #FFFFFF;
    box-shadow: 10px 10px 20px rgba(1, 142, 190, 0.4), -10px -10px 20px #FAFBFF;
    border-radius: 10px;
    width: 485px;
    height: 463px;
    right: 120px;
    top: calc(50% - 231px);
  }
  .block-d .contacts-holder p {
    font-size: 20px;
    line-height: 22px;
  }
  .block-d h6 {
    font-size: 24px;
  }
  .block-d .phone {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 56px;
  }
  h5 {
    display: none;
  }
  h5.mob-hidden {
    display: block;
    font-size: 34px;
    text-align: left;
    margin-bottom: 40px;
  }
  .map-holder {
    padding-bottom: 0;
  }
  .map-holder iframe {
    height: 636px;
  }
  footer {
    margin-top: -7px;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 35px;
    padding-left: 35px;
  }
  .header h1 {
    width: 40%;
  }
  .header p {
    width: 30%;
  }
}
