@import "_variables";
@import "base";



/*---------------------------------
    header
  ---------------------------------*/
  
/*hamburger menu*/
.navbar-toggler{
  padding: 0;
  width: 58px;
  height: 56px;
  z-index: 2;
  //margin: 22px 22px 0 0;
  border: none;
}
.navbar-toggler-icon{
  position: relative!important;
  transition: background-color 0s linear .13s;
  &:before, &:after{
    position: absolute;
    content: "";
    display: block;
    top: 8px;
    transition: top .1s cubic-bezier(.33333,.66667,.66667,1) .2s,transform .13s cubic-bezier(.55,.055,.675,.19);
  }
  &:after{
    display: block;
    top: -8px;
  }
}
.navbar-toggler-icon, .navbar-toggler-icon:before, .navbar-toggler-icon:after{
  position: absolute;
  width: 30px;
  height: 3px;
  transition-timing-function: ease;
  transition-duration: .15s;
  transition-property: transform;
  border-radius: 4px;
  background-color: $color-primary;
}

.navbar-toggler[aria-expanded="true"]{
  .navbar-toggler-icon{
    transition-delay: .22s;
    background-color: transparent!important;
  }
  .navbar-toggler-icon:before{
    top: -12px;
    height: 3px;
    transition: top .1s cubic-bezier(.33333,0,.66667,.33333) .15s,transform .13s cubic-bezier(.215,.61,.355,1) .22s;
    transform: translate3d(0,10px,0) rotate(45deg);
    background-color: $color-primary;
  }
  .navbar-toggler-icon:after{
    top: -12px;
    height: 3px;
    transition: top .2s cubic-bezier(.33333,0,.66667,.33333),transform .13s cubic-bezier(.215,.61,.355,1) .22s;
    transform: translate3d(0,10px,0) rotate(-45deg);
    background-color: $color-primary;
  }
}
/*hamburger menu end*/


.navbar-nav{
  a.nav-link{
  color: $color-black;
  font-size: $font-size-large;
  }
  .phone{
    display: none;
  }
} 

.navbar{
  padding: 9px 0px 10px 10px;
}
.navbar-brand{
  background: url(../images/logo/logo.svg) no-repeat 0 0;
  width: 154px;
  height: 46px;
  padding-top: 0;
  margin-top: 10px;
}

.navbar-collapse{
  padding: 25px 5px;
}

.header{
  padding: 68px 20px 80px 10px;
  background: url(../images/bg/mob-bg.png) no-repeat center;
  h1{
    line-height: 41px;
    padding-bottom: 23px;
  }
  p{
    color: $color-semi-black;
    line-height: 22px;
    font-size: $font-size-xnormal;
  }
  .header-btn{
    display: none;
  }
}
/*---------------------------------
    Content
  ---------------------------------*/

  .block-a{
    justify-content: center;
    padding: 19px 0 0 0;
    h2{
      padding-bottom: 32px;
    }
  }
  .good-section{
    text-align: center;
    padding: 0 25px;
    margin-bottom: 60px;
      img{
        margin: 0 0 0 13px;
      }
      .flx-holder{
        padding: 0 7px 15px 0;
        display: flex;
        h4{
        text-align: left;
        span{padding-top: 5px;
          display: block;
        }
        }

        .price-block{
          padding: 4px 0 0 0;
        }
      }
    .price{
      line-height: 20px;
      display: flex;
      font-size: $font-size-xxnormal;
      text-align: left;
      // padding-left: 38px;
      margin-bottom: -1px;
      strong{
        color: $color-primary;
        font-weight: $font-weight-bold;
        font-size: $font-size-xxnormal;
        padding-right: 6px;
      }
    }
    .terms{
        font-size: $font-size-small;
        margin-bottom: 5px;
        text-align: left;
      }
    .goods-descr{
      text-align: left;
      font-style: italic;
      font-weight: $font-weight-regular;
      font-size: $font-size-small;
      line-height: 16px;
      margin-bottom: 20px;
      span{
        padding-top: 3px;
        display: inline-block;
      }

    }
  }

  .block-b{
    justify-content:center;
    padding: 28px 25px 28px 25px;
    .row{
      width: 100%;
    }
    h2{
      margin-bottom: 59px;
    }
    .cont-holder{
      text-align: center;
      margin-bottom: 59px;
    }
    svg{
      margin-bottom: 16px;
    }
    h3{
      margin-bottom: 15px;
    }
    p{
      line-height: 22px;
    }

  }


  .block-c{
    justify-content:center;
    padding-bottom: 80px;
    h2{
     margin-bottom: 23px;
    }
    p{
      line-height: 22px;
      padding-bottom: 8px;
    }
  }

  .side-logo{
    display: none;
  }

  .block-d{
    text-align: center;
    justify-content:center;
    padding-bottom: 80px;

    h5{
      text-align: center;
      margin-bottom: 20px;
    }
    h6{
      margin-bottom: 5px;
    }
    .phone{
      display: block;
      font-family: Montserrat;
      font-size: $font-size-xxnormal;
      letter-spacing: 0.03em;
      color: $color-black;
      margin-bottom: 17px;
    }
    .address{
      margin-bottom: 9px;
    }
  }

  .mob-hidden{
    display: none;
  }

  .map-holder{
  padding: 0;
  padding-bottom: 33px;
  }

footer{
  text-align: center;
  background: $color-primary;
  padding: 40px 0 80px 0;
}


/*---------------------------------
    Media Queries
  ---------------------------------*/

@media (min-width: 576px) {

  .good-section{
    .flx-holder{
      flex-direction: column;
      h4{
        text-align: center;
      }
      .price-block{
        margin: 0 auto;
      }
    }
    .goods-descr{
      text-align: center;
    }
  }
}


@media (min-width: 768px) {

  .good-section{
    .btn{
      max-width: 246px;
    }
  }

  .img-holder{
    height: 350px;
    margin-bottom: 32px;
    img{
      height: inherit;
    }
  }

  .flx-holder h4{
    min-height: 120px;
  }

  .goods-descr{
    min-height: 70px;
  }

  .price-block{
    min-height: 100px;
  }
  
}

@media (min-width: 992px) {

  .container-fluid{
    background: url(../images/bg/desktop-bg.png) no-repeat right 210px;
    background-size: 50%;
  }
  .navbar {
    padding: 63px 66px 10px 105px;
    align-items: flex-start;
  }

  .navbar-collapse {
      padding: 12px 0 25px 0;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 15px;
    padding-left: 15px;
    &:first-child{
      padding-left: 0;
    }
    &.phone{
      display: block;
      color: $color-primary;
      font-size: $font-size-mlarge;
      font-weight: $font-weight-semibold;
      padding-top: 4px;
      padding-left: 0;
      margin-left: 10%
    }
  }

  .navbar-nav{
    width: 100%;


    a.nav-link{
      font-size: $font-size-xnormal;
    }
  }

  .navbar-expand-lg .navbar-nav{
    justify-content: flex-end;
  }

  .navbar-brand{
    width: 188px;
    height: 144px;
    background: url(../images/logo/logo-desktop.svg) no-repeat 0 0;
    }

    .header {
    background-image: none;
    padding-top: 140px;
    padding-left: 105px;
    margin-bottom: 235px;
    h1{
      line-height: 78px;
      display: block;
      width: 60%;
      padding-bottom: 40px;
    }
    p{
      font-size: $font-size-smlarge;
      line-height: 35px;
      display: block;
      width: 50%;
      margin-bottom: 87px;
    }
      .header-btn{
        display: block;
        width: 246px;
      }
  }


    .block-a{
      margin-bottom: 100px;
      align-items: stretch;
    }
    .block-a h2{
      padding-bottom: 70px;
    }

  .price-block{
    min-height: 65px;
  }
  .price-block span{
    display: flex;
    padding-bottom: 7px;
    align-items: center;
  }

  .good-section .price{
    font-size: $font-size-normal;
    strong{
      font-size: $font-size-smlarge;
    }
  }


  .block-b h2{
    margin-bottom: 100px;
  }

    .block-b .row{
      justify-content: space-evenly;
    }

  .edv-holder{
    justify-content: space-evenly;
  }



  .block-c{
    justify-content: space-evenly;
    align-items:center;
    h2{
      text-align: left;
      margin-bottom: 30px;
    }
    p{margin-bottom: 60px;
      line-height: 38px;

    }
    .btn-primary{
      max-width: 246px;
    }
  }
  .side-logo{
    display: block;
    
    img{
      width: inherit;
    }
  }

  

  .block-d{
    position: relative;
    padding-bottom: 0;
    
    .contacts-holder{
      text-align: left;
      padding: 60px 50px 0 78px;
      position: absolute;
      background-color: $color-white;
      box-shadow: 10px 10px 20px rgba(1, 142, 190, 0.4), -10px -10px 20px #FAFBFF;
      border-radius: 10px;
      width: 485px;
      height: 463px;
      right: 120px ;
      top: calc(50% - 231px);
      p{
        font-size: $font-size-xlnormal;
        line-height: 22px;
      }
    }

    h6{
      font-size: $font-size-smlarge;
    }
    .phone{
      font-size: $font-size-mlarge;
      font-weight: $font-weight-semibold;
      margin-bottom: 56px;
    }
  }

  h5{
    display: none;
      &.mob-hidden{
      display: block;
      font-size: $font-size-xlarge;
      text-align: left;
      margin-bottom: 40px;

      }
    }

  .map-holder{
    iframe{
      height: 636px;
    }
    padding-bottom: 0;
  }


  footer{
    margin-top: -7px;
  }

}


@media (min-width: 1200px) {
.navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 35px;
    padding-left: 35px;
  }


.header h1 {
    width: 40%;
  }
  .header p {
    width: 30%;
  }

}


