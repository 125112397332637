/*---------------------------------
    Fonts
  ---------------------------------*/

$font-montserrat: 'Montserrat', sans-serif;

/*---------------------------------
    Font sizes
  ---------------------------------*/
$font-size-small: 13px;
$font-size-normal: 16px;
$font-size-xnormal: 18px;
$font-size-xlnormal: 20px;
$font-size-xxnormal: 22px;
$font-size-smlarge: 24px;
$font-size-mlarge: 26px;
$font-size-large: 30px;
$font-size-xlarge: 34px;
$font-size-xxlarge: 46px;
$font-size-huge: 64px;

/*---------------------------------
    Font weights
  ---------------------------------*/
$font-weight-bold: 700;
$font-weight-semibold: 600;
$font-weight-regular: 400;

/*--------------------------------
    Colors
  ---------------------------------*/
$color-bg: #F0F5FF;
$color-black: #1B1B1B;
$color-semi-black: rgba(27, 27, 27, 0.7);
$color-white: #FFFFFF;

$color-primary: #018EBE;